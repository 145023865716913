import * as React from "react"
import { motion, AnimatePresence } from "framer-motion"
import { faTimes } from "@fortawesome/free-solid-svg-icons"

import Backdrop from "../backdrop/backdrop"
import IconButton from "../buttons/iconButton"

import "./modal.scss"

const dropIn = {
  hidden: {
    y: "-100vh",
    opacity: 0,
  },
  visible: {
    y: "0",
    opacity: 1,
    transition: {
      duration: 0.1,
      type: "spring",
      damping: 25,
      stiffness: 500,
    },
  },
  exit: {
    y: "100vh",
    opacity: 0,
  },
}

type ModalProps = {
  children: React.ReactNode
  title: string
  open: boolean
  handleClose: React.MouseEventHandler<HTMLButtonElement | HTMLDivElement>
}

const Modal = ({ children, title, open, handleClose }: ModalProps) => {
  return (
    <AnimatePresence>
      {open && (
        <Backdrop onClick={handleClose}>
          <motion.div
            onClick={e => e.stopPropagation()}
            className="modal container"
            variants={dropIn}
            initial="hidden"
            animate="visible"
            exit="exit"
          >
            <div className="modal-container">
              <div className="bg-white p-2">
                <div className="col-xs-12 row justify-space-between align-center mb-2">
                  <h6 className="subtitle1 m-0 text-primary">{title}</h6>
                  <IconButton
                    icon={faTimes}
                    label="Close Modal"
                    onBtnClick={handleClose}
                  />
                </div>
                {children}
              </div>
            </div>
          </motion.div>
        </Backdrop>
      )}
    </AnimatePresence>
  )
}

export default Modal
